import React from "react";
import { Link, FormattedMessage } from "gatsby-plugin-intl";
import Language from "./Language";

export default function Nav({ onMenuToggle = () => { } }) {
  return (
    <nav id="nav">
      <ul>
        <li className="special">
          <a
            href="#menu"
            rel="noopener noreferrer"
            onClick={e => {
              e.preventDefault();
              onMenuToggle();
            }}
            className="menuToggle"
          >
            <span>Menu</span>

          </a>
          <div id="menu">
            <ul>
              <li>
                <Link to="/">
                  <FormattedMessage id="menu_pocetna" />
                </Link>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  href="https://www.abcnatur.sk/product/vatra/"
                  target="_blank"
                >
                  <FormattedMessage id="menu_kupi" />
                </a>
              </li>
              <li>
                <Link to="/Kontakt">
                  <FormattedMessage id="menu_kontakt" />
                </Link>
              </li>
              <li>
                <Link to="/Lekarne">
                  <FormattedMessage id="lekarne" />
                </Link>
              </li>
            </ul>
            <a
              rel="noopener noreferrer"
              className="close"
              onClick={e => {
                e.preventDefault();
                onMenuToggle();
              }}
              href="#menu"
            >
              {""}
            </a>
          </div>
          <Language />
        </li>
      </ul>
    </nav>
  );
}
