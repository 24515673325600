import React from "react";
import {
  IntlContextConsumer,
  changeLocale,
  FormattedMessage
} from "gatsby-plugin-intl";

import engleski from "../assets/images/engleski.png";
import ceski from "../assets/images/ceski.png";
import slovacki from "../assets/images/slovacki.png";

const languageName = {
  cs: ceski,
  en: engleski,
  sk: slovacki
};

const naziviJezika = {
  cs: "czech",
  en: "english",
  sk: "slovensky"
};

const Language = () => {
  const aktivanJezik = `#ee5230`;

  return (
    <div className="aside_languages" style={{ display: "flex", flexDirection: "column" }}>
      {/* <p>
        <FormattedMessage id="futer_OdaberiJezik" />
      </p> */}
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) =>
          languages.map(language => (
            <span className="aside_jezici" key={language} style={{ fontSize: "14px" }}>
              <a
                onClick={() => changeLocale(language)}
                rel="noopener noreferrer"
                href="#!"
                style={{
                  // color: currentLocale === language ? aktivanJezik : null,
                  margin: 10,
                  textDecoration: `none`,
                  borderBottom: 0,
                  cursor: `pointer`
                }}
                className={currentLocale === language ? 'odabran_jezik' : "nije_odabran"}
              >
                <img
                  style={{ width: 45, verticalAlign: "middle" }}
                  src={languageName[language]}
                  alt={`Flag ${naziviJezika[language]}`}
                />
                {/* <span style={{ marginLeft: 12 }}>{naziviJezika[language]}</span> */}
              </a>
            </span>
          ))
        }
      </IntlContextConsumer>
    </div>
  );
};

export default Language;
