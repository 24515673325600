import React from "react";
import Language from "./Language";

export default function Footer() {
  return (
    <footer id="footer">
      {/* <ul className="icons">
        <Language />
      </ul> */}
      <ul className="copyright">
        <li>&copy; Vatra, {new Date().getFullYear()}</li>
        <li>
          Design:{" "}
          <a
            style={{ borderBottom: 0, color: "#fff" }}
            href="https://m2dev.tech"
          >
            M2DEV.TECH
          </a>
        </li>
      </ul>
    </footer>
  );
}
