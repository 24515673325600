import React, { Component } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

import { injectIntl } from "gatsby-plugin-intl";

import "../assets/sass/main.scss";
import Footer from "./Footer";
import SideBar from "./Sidebar";
import Language from "./Language";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPreloaded: true
    };
  }

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ isPreloaded: false });
    }, 100);
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  render() {
    const { children, fullMenu } = this.props;
    const { isPreloaded } = this.state;
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                { name: "description", content: "PRÍRODNÝ GÉL NA PERORÁLNE POUŽITIE RÝCHLA, PEVNÁ A PREDĹŽENÁ EREKCIA" },
                { name: "keywords", content: "Vatra gel, Potencia, Erekcia, sex, erektilná dysfunkcia, Afrodiziakum, afrodiziaka, kamagra, na erekciu, ejakulacie, ejakulacia, afrodiziák, Prírodné" }
              ]}
            >
              <html lang="en" />
            </Helmet>
            <div
              className={
                isPreloaded
                  ? "landing main-body is-preload"
                  : "landing main-body"
              }
            >
              <div id="page-wrapper">
                <SideBar fullMenu={fullMenu} />
                {children}
                <Footer />
              </div>
            </div>
          </>
        )}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default injectIntl(Layout);
